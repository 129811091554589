import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import styled from "styled-components";
import useStore from "../../../context/useStore";
import useRole from "../../../hooks/useRole/useRole";
import Loader from "../Loader";

const ChatContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 350px;
  height: 500px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1000;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: ${(props) =>
    props.isOpen ? "translateY(0)" : "translateY(calc(100% + 20px))"};
  opacity: ${(props) => (props.isOpen ? "1" : "0")};
`;

const ToggleButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    background: #0056b3;
  }

  svg {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease-in-out;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  }
`;

const ChatHeader = styled.div`
  padding: 15px;
  background: #007bff;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 5px;
  font-size: 20px;
  line-height: 1;

  &:hover {
    opacity: 0.8;
  }
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Message = styled.div`
  padding: 10px;
  margin: 5px;
  max-width: 80%;
  border-radius: 10px;
  align-self: ${(props) => (props.isUser ? "flex-end" : "flex-start")};
  background-color: ${(props) => (props.isUser ? "#007bff" : "#f0f0f0")};
  color: ${(props) => (props.isUser ? "white" : "black")};

  .typing-indicator {
    display: inline-block;
    margin-left: 4px;
    animation: typing 1.5s infinite;
  }

  @keyframes typing {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  /* Style pour le contenu Markdown */
  p {
    margin: 0;
    white-space: pre-wrap;
  }

  code {
    background: ${(props) =>
      props.isUser ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre {
    background: ${(props) =>
      props.isUser ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"};
    padding: 8px;
    border-radius: 4px;
    overflow-x: auto;
  }

  ul,
  ol {
    margin: 0;
    padding-left: 20px;
  }

  a {
    color: ${(props) => (props.isUser ? "white" : "#007bff")};
    text-decoration: underline;
  }

  table {
    border-collapse: collapse;
    margin: 8px 0;

    th,
    td {
      border: 1px solid
        ${(props) =>
          props.isUser ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.2)"};
      padding: 4px 8px;
    }
  }

  blockquote {
    margin: 8px 0;
    padding-left: 12px;
    border-left: 3px solid
      ${(props) => (props.isUser ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)")};
  }
`;

const InputContainer = styled.div`
  padding: 15px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 10px;
`;

const Input = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  &:focus {
    border-color: #007bff;
  }
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 86px;
  &:hover {
    background: #0056b3;
  }
`;

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [isStreaming, setIsStreaming] = useState(false);
  const messagesEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [state] = useStore();
  const { myRole } = useRole();

  useEffect(() => {
    if (shouldAutoScroll && messages[messages.length - 1]?.isUser) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, shouldAutoScroll]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const sendMessage = async () => {
    if (!input.trim() || isLoading) return;

    setShouldAutoScroll(true);
    setIsStreaming(false);
    const userMessage = input.trim();
    setInput("");
    setMessages((prev) => {
      const newMessages = [...prev, { text: userMessage, isUser: true }];
      // On utilise un setTimeout pour s'assurer que le DOM est mis à jour
      setTimeout(() => scrollToBottom(), 0);
      return newMessages;
    });
    setIsLoading(true);

    try {
      setIsStreaming(true);
      // Créer un message temporaire pour la réponse de l'assistant
      setMessages((prev) => [
        ...prev,
        { text: "", isUser: false, isStreaming: true },
      ]);

      const response = await fetch(`/api/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message: userMessage,
          userId: state.auth.user.id,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const events = decoder.decode(value).split("\n\n");
        for (const event of events) {
          if (!event.trim()) continue;

          const lines = event.split("\n");
          const eventType = lines[0].replace("event: ", "");
          const data = JSON.parse(lines[1].replace("data: ", ""));

          switch (eventType) {
            case "delta":
              setMessages((prev) => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];
                if (!lastMessage.isUser) {
                  lastMessage.text += data.delta;
                }
                return newMessages;
              });
              break;
            case "error":
              console.error("Streaming error:", data.error);
              setMessages((prev) => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];
                if (!lastMessage.isUser) {
                  lastMessage.text =
                    "Une erreur est survenue lors du traitement de votre message.";
                  lastMessage.isStreaming = false;
                }
                return newMessages;
              });
              setIsStreaming(false);
              break;
            case "end":
              setMessages((prev) => {
                const newMessages = [...prev];
                const lastMessage = newMessages[newMessages.length - 1];
                if (!lastMessage.isUser) {
                  lastMessage.isStreaming = false;
                }
                return newMessages;
              });
              setIsStreaming(false);
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prev) => [
        ...prev,
        {
          text: "Désolé, une erreur est survenue lors du traitement de votre message.",
          isUser: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  // If user is not super admin, don't render the chat
  if (!state.auth.isAuthenticated || !myRole === "ADMIN") {
    console.log("User is not super admin");
    return null;
  }

  if (process.env.REACT_APP_CHAT_ENABLED !== "true") {
    console.log("Chat is disabled");
    return null;
  }

  return (
    <>
      <ToggleButton onClick={toggleChat} isOpen={isOpen}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          {isOpen ? (
            <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="currentColor"
            />
          ) : (
            <path
              d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H6L4 18V4H20V16Z"
              fill="currentColor"
            />
          )}
        </svg>
      </ToggleButton>
      <ChatContainer isOpen={isOpen} ref={chatContainerRef}>
        <ChatHeader>
          Perceval Assistant
          <CloseButton onClick={toggleChat}>×</CloseButton>
        </ChatHeader>
        <MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} isUser={message.isUser}>
              {message.isUser ? (
                message.text
              ) : (
                <>
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      p: ({ node, ...props }) => (
                        <p style={{ margin: 0 }} {...props} />
                      ),
                      a: ({ node, ...props }) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          {...props}
                        />
                      ),
                    }}
                  >
                    {message.text}
                  </ReactMarkdown>
                  {message.isStreaming && (
                    <span className="typing-indicator">...</span>
                  )}
                </>
              )}
            </Message>
          ))}
          <div ref={messagesEndRef} />
        </MessagesContainer>
        <InputContainer>
          <Input
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Écrivez votre message..."
            disabled={isLoading}
          />
          <SendButton onClick={sendMessage} disabled={isLoading}>
            {isLoading ? <Loader size={20} color="white" /> : "Envoyer"}
          </SendButton>
        </InputContainer>
      </ChatContainer>
    </>
  );
};

export default Chat;
