import { useContext } from "react";
import { TooltipButton } from "../../../../../../../common/Tooltip/Tooltip";
import { ClientContext } from "../../../../ClientContext/ClientContext";
import useCompanyPolicies from "../BottomPanel/BPPolicies/useCompanyPolicies";
import useCompanyItem from "../useCompanyItem";

const CompanyPoliciesRecap = () => {
  const { company, actions } = useCompanyItem();
  const { productTypes } = useContext(ClientContext);
  const { onChangePolicyStatusFilter } = useCompanyPolicies();

  // const policiesStatus = company.policies.map((p) => tools.findIn(POLICIES_STATUS, p.status, "id"));
  const POLICY_STATUS_CANCELED = [
    20, // Suspendu
    30, // Résilié
  ];

  const nbPolicies = company.policies?.length || 0;
  const companyProducts = {};
  let policiesCanceled = [];
  let policiesValid = [];

  if (company.policies) {
    /**
     * Get only policies with status canceled
     */
    policiesCanceled = company.policies.filter((p) =>
      POLICY_STATUS_CANCELED.includes(p.status)
    );

    /**
     * Get only policies with valid status
     */
    policiesValid = company.policies.filter(
      (p) => !POLICY_STATUS_CANCELED.includes(p.status)
    );

    policiesValid.forEach((policy) => {
      const productTypeRef = productTypes.find(
        (p) => p.id == policy?.product?.productType
      );

      if (productTypeRef) {
        const producerName = `${
          policy.producer?.company || policy.producer2?.company || ""
        }`;

        let entryRef = companyProducts[policy.product?.productType] || {
          count: 0,
          name: productTypeRef.name,
          productTypeModulrId: productTypeRef.modulrId,
          producerNames: producerName ? [producerName] : [],
          productType: policy.product?.productType,
        };

        companyProducts[policy.product?.productType] = {
          ...entryRef,
          status: policy.status,
          count: entryRef.count + 1,
          avecEcheancier: policy.avecEcheancier,
          avecMensualisation: policy.avecMensualisation,
          producerNames:
            entryRef.producerNames.includes(producerName) && producerName
              ? entryRef.producerNames
              : [...entryRef.producerNames, producerName],
        };
      }
    });
  }
  const openPolicy = (product, isCanceled = false) => {
    actions.openBottomPanel("POLICIES", product);
    if (isCanceled) {
      onChangePolicyStatusFilter({ target: { value: [20, 30] } });
    } else {
      onChangePolicyStatusFilter({ target: { value: [0, 10] } });
    }
  };

  console.log("companyProducts", companyProducts);

  return (
    <>
      <div>
        <strong>{nbPolicies} contrat(s)</strong>
        {Object.values(companyProducts).map((product) => {
          return (
            <div
              className="d-flex align-items-center justify-content-start text-primary"
              key={`cpproduct${company.id}${product.name}`}
            >
              <div
                className="d-flex align-items-center justify-content-start cursor-pointer  hover-color-success"
                onClick={() => openPolicy(product)}
                style={{ fontWeight: "600" }}
              >
                <span className="badge badge-primary mr-1">
                  {product.count}
                </span>
                {product.name}{" "}
                {product.avecEcheancier && (
                  <span className="generic-tooltip">
                    <span
                      className="badge d-center bg-warning ml-1 rounded-circle text-white p-0"
                      style={{
                        width: 15,
                        height: 15,
                      }}
                    >
                      E
                    </span>
                    <span className="generic-tooltip-content text-dark">
                      Avec échéancier
                    </span>
                  </span>
                )}
                {product.avecMensualisation && (
                  <span className="generic-tooltip">
                    <span
                      className="badge d-center bg-warning ml-1 rounded-circle text-white p-0"
                      style={{
                        width: 15,
                        height: 15,
                      }}
                    >
                      M
                    </span>
                    <span className="generic-tooltip-content text-dark">
                      Avec mensualisation
                    </span>
                  </span>
                )}
              </div>
              {product.producerNames.includes("PERCEVAL") &&
              product.producerNames.length == 1 ? null : (
                <TooltipButton
                  tooltipContent={
                    <div className="px-1">
                      <div>
                        <strong style={{ whiteSpace: "nowrap" }}>
                          Gestionnaires :
                        </strong>
                      </div>
                      {product.producerNames.join(", ")}
                    </div>
                  }
                >
                  <i className="text-primary fa fa-handshake" />
                </TooltipButton>
              )}
            </div>
          );
        })}

        {!policiesCanceled.length ? null : (
          <div
            className="d-flex align-items-center justify-content-start text-primary cursor-pointer"
            onClick={() => openPolicy(policiesCanceled[0].product, true)}
            style={{ fontWeight: "600" }}
            // key={`cpproduct${company.id}${product.name}`}
          >
            <span className="badge badge-danger mr-1">
              {policiesCanceled.length}
            </span>
            Résilié(s) / Suspendu(s)
          </div>
        )}
      </div>
    </>
  );
};

export default CompanyPoliciesRecap;
