import React from "react";
import Loader from "./Loader";

const styles = {
  transition: "0.2s",
};

const SaveBtn = ({
  save = () => {},
  isSaving,
  isSaved,
  text = "Enregistrer",
  type = "success",
  className = "",
  typeBtn = "button",
  loaderColor = "#ffffff",
  dataPriv = "",
  style = {},
  disabled = false,
  loadingText=""
}) => {
  return (
    <button
      disabled={isSaving || disabled}
      data-priv={dataPriv}
      type={typeBtn}
      className={"d-flex align-items-center btn btn-" + type + " " + className}
      onClick={(e) => {
        if (e) e.stopPropagation();
        save();
      }}
      style={
        isSaving
          ? { ...styles, opacity: ".7", ...style }
          : { ...styles, ...style }
      }
    >
      <span className={isSaving || isSaved ? "d-none" : ""}>{text}</span>
      {isSaving && <><Loader color={loaderColor} size={21} />{loadingText&& <span className="ml-2">{loadingText}</span>}</>}
      {isSaved && !isSaving && <i className="fa fa-check" />}
    </button>
  );
};

export default SaveBtn;
