import { DateTime } from "luxon";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../../../../../../helpers/dataService";
import tools from "../../../../../../../../../../helpers/tools";

const useBPDocumentItem = (doc, docMeta) => {
  const [document, setDocument] = useState(doc);
  const [isDownloadingDocId, setIsDownloadingDocId] = useState(null);
  const [isRemovingDocId, setIsRemovingDocId] = useState(null);
  const [isUploadingDocId, setIsUploadingDocId] = useState(null);
  const [showOlder, setShowOlder] = useState(false);
  const docInfos = document[0];
  const otherListRef = useRef(null);
  console.log("docInfos", docInfos);

  const onDownload = (docInfo, preview = false) => {
    setIsDownloadingDocId(docInfo.id);
    let docExt = docInfo.fileName.split(".");
    docExt = docExt[docExt.length - 1];
    dataService.downloadFile(
      `files/${docInfo.id}`,
      {
        preview,
        filename: tools.getDocumentName({
          fileExt: docExt,
          documentName: docInfo?.fileType?.name || "",
          productName: docMeta?.productName || "",
          companyName: docMeta?.companyName || "",
          uploadedAt: DateTime.fromISO(docInfo.uploadedAt).toFormat("yyyy"),
        }),
      },
      (data) => {},
      () => {},
      () => setIsDownloadingDocId(null)
    );
  };
  const onRemove = (docId) => {
    if (!window.confirm("Confirmez la suppression du fichier")) return false;
    setIsRemovingDocId(docId);
    dataService.remove(
      `files/${docId}`,
      {},
      (data) => {
        toast.success("Document supprimé");
        setDocument((document) =>
          document.length == 1
            ? [
                {
                  ...docInfos,
                  id: null,
                  fileName: "",
                },
              ]
            : document.filter((d) => d.id !== docId)
        );
      },
      () => {},
      () => setIsRemovingDocId(null),
      true
    );
  };

  const onUpload = (files) => {
    let data = new FormData();
    data.append("file", files[0]);
    data.append("fileType", docInfos.fileType.id);
    data.append("entityId", docInfos.entity.id);
    data.append("entityRef", docInfos.entity.ref);
    setIsUploadingDocId(true);
    dataService.postFile(
      `files`,
      data,
      (data) => {
        toast.success("Document envoyé");
        setDocument(docInfos.id ? [...document, data] : [data]);
      },
      (err) => {},
      () => setIsUploadingDocId(null)
    );
  };

  const toggleShowOlder = () => setShowOlder(!showOlder);

  return {
    document,
    isDownloadingDocId,
    isRemovingDocId,
    isUploadingDocId,
    showOlder,
    docInfos,
    otherListRef,
    actions: {
      onDownload,
      onRemove,
      onUpload,
      toggleShowOlder,
    },
  };
};

export default useBPDocumentItem;
