import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import PrivateRoute from "./components/common/PrivateRoute";
// Pages
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoleStyle from "./RoleStyle";
import ScrollToTop from "./ScrollToTop";
import CustomModal from "./components/common/CustomModal/CustomModal";
import CustomSidenav from "./components/common/CustomSidenav/CustomSidenav";
import Loader from "./components/common/Loader";
import NotifModal from "./components/common/Notifications/NotifModal/NotifModal";
import Sidenav from "./components/common/layout/Sidenav";
import TopHeader from "./components/common/layout/TopHeader";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import AddCompanyBlackList from "./components/pages/BlackList/AddCompanyBlackList/AddCompanyBlackList";
import BlackList from "./components/pages/BlackList/BlackList";
import Forms from "./components/pages/ClientList/Forms/Forms";
import ModifyAnswers from "./components/pages/ClientList/ModifyAnswers/ModifyAnswers";
import ClientListPage from "./components/pages/ClientListPage/ClientListPage";
import ClientModulrList from "./components/pages/ClientModulr/ClientModulrList";
import ClientTempList from "./components/pages/ClientTempList/ClientTempList";
import Constants from "./components/pages/Constants/Constants";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import DocumentList from "./components/pages/DocumentList/DocumentList";
import Documentation from "./components/pages/Documentation/Documentation";
import DynamicPageDetail from "./components/pages/DynamicPageDetail/DynamicPageDetail";
import DynamicPageList from "./components/pages/DynamicPageList/DynamicPageList";
import FAQList from "./components/pages/FAQ/FAQList/FAQList";
import FileCategoryDetail from "./components/pages/FileCategoryDetail/FileCategoryDetail";
import FileCategoryList from "./components/pages/FileCategoryList/FileCategoryList";
import FileTypeDetail from "./components/pages/FileTypeDetail/FileTypeDetail";
import FileTypeList from "./components/pages/FileTypeList/FileTypeList";
import FtpClient from "./components/pages/FtpClient/FtpClient";
import HintDetail from "./components/pages/HintDetail/HintDetail";
import HintList from "./components/pages/HintList/HintList";
import ImportData from "./components/pages/ImportData/ImportData";
import LeadListDomiPage from "./components/pages/LeadListPage/LeadListDomiPage";
import LeadListPage from "./components/pages/LeadListPage/LeadListPage";
import MyAccount from "./components/pages/MyAccount/MyAccount";
import MyTimeline from "./components/pages/MyTimeline/MyTimeline";
import CommissionsList from "./components/pages/Partners/CommissionsList/CommissionsList";
import PartnersList from "./components/pages/Partners/PartnersList/PartnersList";
import PjRenewList from "./components/pages/PjRenewList/PjRenewList";
import RBEList from "./components/pages/RBEList/RBEList";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import RoleTemplate from "./components/pages/RoleTemplate/RoleTemplate";
import SimplifiedFormListPage from "./components/pages/SimplifiedFormListPage/SimplifiedFormListPage";
import Simulator from "./components/pages/Simulator/Simulator";
import TestimonialDetail from "./components/pages/TestimonialDetail/TestimonialDetail";
import TestimonialList from "./components/pages/TestimonialList/TestimonialList";
import UserDetail from "./components/pages/UserDetail/UserDetail";
import UserList from "./components/pages/UserList/UserList";
import Chat from "./components/common/Chat/Chat";
import { ExternalConstantsContext } from "./hooks/useExternalConstants/ExternalConstantsContext";
import ApiAuthMiddleware from "./middlewares/ApiAuthMiddleware";
import useApp from "./useApp";
import Colors from "./variables.scss";

window.borderRadius = Colors.borderRadius;

export const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // -> 0.25 in production
  tracesSampleRate: 0.25,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const { state, externalConstants } = useApp();

  return (
    <ApiAuthMiddleware>
      <RoleStyle>
        <ExternalConstantsContext.Provider value={externalConstants}>
          <Router history={history}>
            <ScrollToTop />
            <div className="wrapper">
              <Sidenav />
              <div
                id="content"
                className={`${state.auth.isAuthenticated ? "connected" : ""}`}
              >
                <TopHeader />
                <ToastContainer
                  position="top-right"
                  autoClose={4000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnVisibilityChange
                  draggable
                  pauseOnHover
                />
                {/* Same as */}
                {Object.keys(state.constants.items).length > 0 ? (
                  <Switch>
                    <SentryRoute
                      path="/renew-password/:token"
                      exact
                      component={RenewPassword}
                    />
                    <SentryRoute
                      path="/simulator"
                      exact
                      component={Simulator}
                    />
                    <SentryRoute
                      path="/activate-account/:token"
                      exact
                      component={ActivateAccount}
                    />
                    <PrivateRoute path="/" exact component={Dashboard} />
                    <PrivateRoute
                      path="/dashboard"
                      exact
                      component={Dashboard}
                    />
                    <PrivateRoute path="/users" exact component={UserList} />
                    <PrivateRoute
                      path="/users/:id"
                      exact
                      component={UserDetail}
                    />
                    <PrivateRoute
                      path="/roles"
                      exact
                      component={RoleTemplate}
                    />
                    <PrivateRoute
                      path="/constants"
                      exact
                      component={Constants}
                    />
                    <PrivateRoute
                      path="/testimonials"
                      exact
                      component={TestimonialList}
                    />
                    <PrivateRoute
                      path="/testimonials/:id"
                      exact
                      component={TestimonialDetail}
                    />
                    <PrivateRoute path="/me" exact component={MyAccount} />
                    <PrivateRoute
                      path="/dynamic-pages"
                      exact
                      component={DynamicPageList}
                    />
                    <PrivateRoute
                      path="/dynamic-pages/:id"
                      exact
                      component={DynamicPageDetail}
                    />
                    <PrivateRoute
                      path="/import-data"
                      exact
                      component={ImportData}
                    />

                    <PrivateRoute
                      path="/document-categories"
                      exact
                      component={FileCategoryList}
                    />
                    <PrivateRoute
                      path="/document-categories/:id"
                      exact
                      component={FileCategoryDetail}
                    />
                    <PrivateRoute
                      path="/file-types"
                      exact
                      component={FileTypeList}
                    />
                    <PrivateRoute
                      path="/file-types/:id"
                      exact
                      component={FileTypeDetail}
                    />
                    <PrivateRoute
                      path="/files"
                      exact
                      component={DocumentList}
                    />
                    <PrivateRoute
                      path="/clients-temp"
                      exact
                      component={ClientTempList}
                    />
                    <PrivateRoute
                      path="/clients-modulr"
                      exact
                      component={ClientModulrList}
                    />
                    <PrivateRoute
                      path="/timeline"
                      exact
                      component={MyTimeline}
                    />
                    <PrivateRoute path="/hints" exact component={HintList} />
                    <PrivateRoute
                      path="/hints/:id"
                      exact
                      component={HintDetail}
                    />
                    <PrivateRoute
                      path="/partners"
                      exact
                      component={PartnersList}
                    />

                    <PrivateRoute
                      path="/ftpclient"
                      exact
                      component={FtpClient}
                    />
                    <PrivateRoute path="/faqs" exact component={FAQList} />
                    <PrivateRoute
                      path="/forms/:clientId"
                      exact
                      component={Forms}
                    />
                    <PrivateRoute
                      path="/modify-answers/:clientId/:productId/:establishmentId/:quotationId"
                      exact
                      component={ModifyAnswers}
                    />
                    <PrivateRoute
                      path="/pj-renew"
                      exact
                      component={PjRenewList}
                    />
                    <PrivateRoute
                      path="/leads"
                      exact
                      component={LeadListPage}
                    />
                    <PrivateRoute
                      path="/leads-domi"
                      exact
                      component={LeadListDomiPage}
                    />
                    <PrivateRoute
                      path="/leads"
                      exact
                      component={LeadListPage}
                    />
                    <PrivateRoute path="/rbe-list" exact component={RBEList} />
                    <PrivateRoute
                      path="/black-list"
                      exact
                      component={BlackList}
                    />
                    <PrivateRoute
                      path="/black-list/add-establishment"
                      exact
                      component={AddCompanyBlackList}
                    />
                    <PrivateRoute
                      path="/client-list"
                      exact
                      component={ClientListPage}
                    />
                    <PrivateRoute
                      path="/partner-commissions"
                      exact
                      component={CommissionsList}
                    />
                    <PrivateRoute
                      path="/leads-internes"
                      exact
                      component={SimplifiedFormListPage}
                    />
                    <PrivateRoute
                      path="/documentation"
                      exact
                      component={Documentation}
                    />
                  </Switch>
                ) : (
                  <Loader withContainer containerStyle={{ height: "100%" }} />
                )}
                <Chat />
              </div>
            </div>
            <CustomModal />
            <CustomSidenav />
            {state.auth.isAuthenticated ? <NotifModal /> : null}
          </Router>
        </ExternalConstantsContext.Provider>
      </RoleStyle>
    </ApiAuthMiddleware>
  );
};

export default App;
