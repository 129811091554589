import { atom, useAtom } from "jotai";
import dataService from "../../../../../../../../../helpers/dataService";
import { toast } from "react-toastify";

const companyDocumentsAtom = atom([]);
const isLoadingDocumentsAtom = atom(false);
const isSyncingDocumentsAtom = atom(false);

const useCompanyDocuments = () => {
  const [companyDocuments, setCompanyDocuments] = useAtom(companyDocumentsAtom);
  const [isLoadingDocuments, setIsLoadingDocuments] = useAtom(
    isLoadingDocumentsAtom
  );
  const [isSyncingDocuments, setIsSyncingDocuments] = useAtom(
    isSyncingDocumentsAtom
  );

  const getCompanyDocuments = (companyId) => {
    setIsLoadingDocuments(true);
    dataService.get(
      `clients/${companyId}/files`,
      setCompanyDocuments,
      (err) => {},
      () => setIsLoadingDocuments(false)
    );
  };

  const syncPublicDocuments = (companyId) => {
    setIsSyncingDocuments(true);
    setIsLoadingDocuments(true);
    return dataService.patch(
      `clients/${companyId}/sync-documents-publics`,
      null,
      () => {
        // Refresh the documents list after sync
        getCompanyDocuments(companyId);
        toast.success("Les documents publics ont été synchronisés avec succès");
      },
      (err) => {
        getCompanyDocuments(companyId);
        toast.error(
          err?.response?.data?.other ||
            "Une erreur est survenue lors de la synchronisation"
        );
      },
      () => {
        setIsSyncingDocuments(false);
      }
    );
  };

  console.log("companyDocuments", companyDocuments);
  return {
    documentState: {
      isLoadingDocuments,
      companyDocuments,
      isSyncingDocuments,
    },
    actions: {
      getCompanyDocuments,
      syncPublicDocuments,
    },
  };
};

export default useCompanyDocuments;
