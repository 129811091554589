import { DateTime } from "luxon";
import { useState } from "react";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";

const useDocumentList = () => {
  const [isDownloadingDocId, setIsDownloadingDocId] = useState(false);

  const downloadDocument = (doc, preview = false) => {
    setIsDownloadingDocId(doc.id);
    let docExt = doc.fileName.split(".");
    docExt = docExt[docExt.length - 1];
    dataService.downloadFile(
      `files/${doc.id}`,
      {
        preview,
        filename: tools.getDocumentName({
          fileExt: docExt,
          documentName: doc?.fileType?.name || "",
          productName: doc?.productName || "",
          companyName: doc?.companyName || "",
          uploadedAt: DateTime.fromISO(doc.uploadedAt).toFormat("yyyy"),
        }),
      },
      () => {},
      () => {},
      () => setIsDownloadingDocId(false)
    );
  };

  return {
    isDownloadingDocId,
    actions: {
      downloadDocument,
    },
  };
};

export default useDocumentList;
