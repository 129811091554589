import Axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { API_URL } from "../config";

const dataService = {
  getMany: (
    urls,
    successCallback = () => {},
    beforeCallBack = () => {},
    anyCallBack = () => {}
  ) => {
    beforeCallBack();
    var allPromises = [];
    urls.forEach((url) => {
      switch (url.type) {
        case "GET":
          allPromises.push(
            new Promise((resolve, reject) => {
              dataService.get(
                url.path,
                (datas) => resolve({ name: url.name, datas }),
                reject,
                () => {}
              );
            })
          );
          break;
        case "POST":
          allPromises.push(
            new Promise((resolve, reject) => {
              dataService.post(
                url.path,
                url.postData,
                (datas) => resolve({ name: url.name, datas }),
                reject,
                () => {}
              );
            })
          );
          break;
      }
    });
    var results = {};
    Promise.all(allPromises)
      .then((values) => {
        values.forEach((val) => {
          results[val.name] = val.datas;
        });
        successCallback(results);
      })
      .catch((err) => {})
      .then(anyCallBack);
  },
  get: (
    url,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.get(API_URL + url)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  post: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  postFile: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  patch: (
    url,
    postData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.patch(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  remove: (
    url,
    deleteData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {},
    noConfirm = false
  ) => {
    if (!noConfirm) {
      if (!window.confirm("Supprimer cette entrée ?")) {
        anyCallBack();
        return false;
      }
    }
    Axios.delete(API_URL + url, deleteData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  errorDisplay: (err, errorCallback) => {
    if (
      err.response &&
      err.response.data &&
      Object.keys(err.response.data).length > 0 &&
      typeof err.response.data === "object"
    ) {
      Object.keys(err.response.data).forEach((key) => {
        var val = err.response.data[key];
        if (key != "assignedToExists") toast.error(val);
      });
      errorCallback(err.response.data);
    } else {
      errorCallback({ other: "Une erreur est survenue dans l'application" });
    }
  },
  getMimeType: (filename) => {
    const ext = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
      'pdf': 'application/pdf',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'bmp': 'image/bmp',
      'webp': 'image/webp',
      'tiff': 'image/tiff',
      'tif': 'image/tiff'
    };
    return mimeTypes[ext] || 'application/octet-stream';
  },
  downloadFile: (
    url,
    options = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    if (options.preview) {
      // En mode preview, on fait une requête avec des headers spécifiques
      const mimeType = dataService.getMimeType(options.filename || '');
      
      Axios({
        url: API_URL + url,
        method: "GET",
        responseType: "blob",
        headers: {
          "Content-Type": mimeType,
          "Content-Disposition": "inline",
        },
      })
        .then((res) => {
          const blobUrl = window.URL.createObjectURL(
            new Blob([res.data], { type: mimeType })
          );
          window.open(blobUrl, "_blank");
          setTimeout(() => window.URL.revokeObjectURL(blobUrl), 1000);
          successCallback(res.data);
        })
        .catch((error) => {
          errorCallback(error);
        })
        .then(anyCallBack);
      return;
    }

    // Mode téléchargement normal
    Axios({
      url: API_URL + url,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        const filename =
          options.filename || res.headers["content-disposition"].split("=")[1];
        fileDownload(res.data, filename);
        successCallback(res.data);
      })
      .catch((error) => {
        console.error("Download error:", error);
        errorCallback(error);
      })
      .then(anyCallBack);
  },
};

export default dataService;
