import { useEffect } from "react";
import useCompanyItem from "../../useCompanyItem";
import BPDocumentItem from "./BPDocumentItem/BPDocumentItem";
import useCompanyDocuments from "./useCompanyDocuments";
import SaveBtn from "../../../../../../../../common/SaveBtn";

const BPDocuments = ({ docType }) => {
  const { company } = useCompanyItem();
  const { documentState, actions } = useCompanyDocuments();

  useEffect(() => {
    actions.getCompanyDocuments(company.id);
  }, []);

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-end">
        <SaveBtn 
          save={() => actions.syncPublicDocuments(company.id)}
          isSaving={documentState.isSyncingDocuments}
          text={<><i className="fa fa-refresh mr-2" />Synchroniser les documents publics</>}
          dataPriv="bo_majdocumentspublicsclient"
          className="btn-sm"
          type="default"
          loaderColor="#000000"
          loadingText="Synchronisation en cours..."
        />
      </div>
      {!documentState.isLoadingDocuments && documentState.companyDocuments.map((document) => {
        return (
          <div
            className="col-12 col-md-6 col-lg-4 p-2"
            key={`docgroup${document.map((d) => d.fileType.name).join("-")}`}
          >
            <BPDocumentItem
              nbDocs={document.length}
              doc={document}
              docMeta={{ productName: "", companyName: company?.company || "" }}
              docType={docType}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BPDocuments;
