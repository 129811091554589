import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import Control from "../../common/Control";
import FileTypeFilter from "./FileTypeFilter";
import Pagination from "../../common/Pagination";
import Table from "../../common/Table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const FileTypeList = () => {
  const [types, setTypes] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [search, setSearch] = useState({
    filters: { terms: "" },
    page: 1,
  });
  const [isSearching, setIsSearching] = useState(false);
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    searchTypes();
    getCategories();
  }, []);

  function getCategories() {
    Axios.get(API_URL + "filecategories")
      .then((res) => {
        setCategories(res.data);
        var allFilters = [];
        res.data.forEach((d) => {
          d.filters.forEach((df) => {
            if (!allFilters.find((f) => f.id == df.id)) {
              allFilters.push({
                id: df.id,
                type: df.type,
                datas: df.datas,
              });
            }
          });
        });
        setFilters(allFilters);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  function searchTypes(overrideSearch = false) {
    setErrors(false);
    setIsLoading(true);
    Axios.post(API_URL + "filetypes/search", overrideSearch || search)
      .then((res) => {
        setTypes(res.data.data);
        setNbResults(res.data.count);
        setNbPages(Math.ceil(parseInt(res.data.count) / 25));
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
        setTypes([]);
      })
      .then(() => {
        setIsLoading(false);
      });
  }
  function changePage(i) {
    setSearch((search) => {
      return { ...search, page: i };
    });
    searchTypes({ ...search, page: i });
  }
  function updateSearch(e) {
    var { name, value } = e.target;
    var su = { ...search };
    if (value == "") {
      delete su.filters[name];
    } else {
      su.filters[name] = value;
    }

    setSearch(su);
  }
  function removeType(id) {
    if (
      !window.confirm("Confirmez-vous la suppression de ce type de document ?")
    )
      return false;
    setIsLoading(true);
    Axios.delete(API_URL + "filetypes/" + id)
      .then((res) => {
        searchTypes();
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
          toast.error("Une erreur est survenue lors de la suppression");
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  }

  if (categories.length === 0) return null;

  return (
    <Page
      container="container-fluid"
      title={"Liste des types de document"}
      errors={errors}
      action={{ to: "/file-types/create", text: "Créer un type de document" }}
    >
      <FileTypeFilter
        search={search}
        types={types}
        updateSearch={updateSearch}
        startSearch={() => searchTypes()}
        nbResults={nbResults}
        isLoading={isLoading}
      />
      <div className="row">
        <div className="col-12 mt-3">
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader withContainer={true} />
          ) : (
            <Table
              datas={types}
              fields={[
                {
                  name: "Nom",
                  path: "name",
                },
                {
                  name: "Code",
                  path: "code",
                },
                {
                  name: "Catégories",
                  path: "*",
                  render: (elem) =>
                    elem.fileCategories
                      .map((cat) => {
                        var category = categories.find((c) => c.id == cat);
                        if (!category) return "";
                        return category.name;
                      })
                      .join(", "),
                },
                {
                  name: "Modifier",
                  path: "id",
                  render: (elem) => (
                    <Link
                      to={"/file-types/" + elem}
                      style={{ minWidth: 0 }}
                      className="btn btn-sm w-100 btn-default d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-cog" />
                    </Link>
                  ),
                },
                {
                  name: "Supprimer",
                  path: "id",
                  render: (elem) => (
                    <button
                      onClick={() => removeType(elem)}
                      style={{ minWidth: 0 }}
                      className="btn btn-sm w-100 btn-danger d-flex align-items-center justify-content-center"
                    >
                      <i className="fa fa-times" />
                    </button>
                  ),
                },
              ]}
            />
          )}
        </div>
      </div>

      <Pagination
        nbPages={nbPages}
        page={search.page}
        changePage={changePage}
      />
    </Page>
  );
};

export default FileTypeList;
