import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import Control from "../../common/Control";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";
import Axios from "axios";
import { API_URL } from "../../../config";
import SaveBtn from "../../common/SaveBtn";
import { toast } from "react-toastify";
import CharCounter from "../../common/CharCounter";
import SimpleDynamicList from "../../common/SimpleDynamicList";

const FileTypeDetail = (props) => {
  const [state, dispatch] = useStore();

  const [type, setType] = useState({
    fileCategories: [],
    name: "",
    code: "",
  });
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    getDatas();
    if (props.match.params.id != "create") {
      setIsLoading(true);
      Axios.get(API_URL + "filetypes/" + props.match.params.id)
        .then((res) => {
          setType(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
          toast.error(
            "Une erreur est survenue lors de la récupération du type"
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, []);

  function getDatas() {
    Axios.get(API_URL + "filecategories")
      .then((res) => {
        setCategories(res.data);
        var allFilters = [];
        res.data.forEach((d) => {
          d.filters.forEach((df) => {
            if (!allFilters.find((f) => f.id == df.id)) {
              allFilters.push({
                id: df.id,
                type: df.type,
                datas: df.datas,
              });
            }
          });
        });
        setFilters(allFilters);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      });
  }

  function updateData(e) {
    var { name, value } = e.target;

    console.log(name, value);
    setType({ ...type, [name]: value });
  }

  function save() {
    setIsSaving(true);
    setIsSaved(false);
    setErrors({});
    if (props.match.params.id == "create") {
      //create

      Axios.post(API_URL + "filetypes/", type)
        .then((res) => {
          setType(res.data);
          setIsSaved(true);
          toast.success("La type a bien été créée");
          window.location.href = "/file-types/" + res.data.id;
        })
        .catch((err) => {
          toast.error("Une erreur est survenue lors de la création du type");
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    } else {
      //update
      Axios.patch(API_URL + "filetypes/" + props.match.params.id, type)
        .then((res) => {
          setType(res.data);
          setIsSaved(true);
          toast.success("Le type a bien été modifiée");
        })
        .catch((err) => {
          toast.error(
            "Une erreur est survenue lors de la modification du type"
          );
          if (err.response && err.response.data) {
            setErrors(err.response.data);
          }
        })
        .then(() => {
          setIsSaving(false);
        });
    }
  }

  console.log("type.fileCategories", type, categories);

  return state.constants.items.length == 0 ||
    isLoading ||
    categories.length == 0 ? (
    <Loader withContainer={true} />
  ) : (
    <Page
      container={"container-fluid px-5"}
      title={
        (props.match.params.id == "create" ? "Création" : "Modification") +
        " d'un type de document"
      }
      errors={errors}
      back={"/file-types"}
    >
      <Control
        label="Nom"
        name="name"
        value={type.name}
        change={updateData}
        error={errors}
      />
      <Control
        label="Code"
        name="code"
        value={type.code}
        change={updateData}
        error={errors}
      />

      <SimpleDynamicList
        listLabel="Catégories"
        uniqueKey="1"
        element="fileCategories"
        change={updateData}
        fields={[
          {
            label: "Catégorie",
            type: "select",
            name: "id",
            datas: categories,
            dataIndex: "id",
            dataLabel: "name",
          },
        ]}
        items={type.fileCategories}
        btnLabel="Ajouter une catégorie"
        error={errors}
      />

      <SaveBtn
        className="mt-4"
        save={save}
        isSaving={isSaving}
        isSaved={isSaved}
      />
    </Page>
  );
};

export default FileTypeDetail;
