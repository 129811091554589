import { DateTime } from "luxon";
import styled from "styled-components";
import tools from "../../../helpers/tools";
import Loader from "../Loader";

const FileItem = ({ doc, onDownload, isDownloading }) => {
  const hasFile = doc.fileName;

  return (
    <StyledDocumentItem className="shadow-sm">
      <p className="doc__date jUIMQa">
        {hasFile
          ? `Envoyé le ${tools.formatDate(doc.uploadedAt)}`
          : "Non envoyé"}
      </p>
      <div className="doc__name">{doc.fileType.name}</div>

      {doc.fileName && (
        <>
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column">
              <button
                className="btn btn-sm btn-link border-0"
                onClick={() => onDownload(doc, true)}
              >
                {isDownloading ? (
                  <Loader size={15} />
                ) : (
                  <i className="fa fa-eye text-primary" />
                )}
              </button>
              {isDownloading ? (
                <Loader />
              ) : (
                <button
                  className="btn  btn-primary btn-sm "
                  onClick={() => onDownload(doc)}
                >
                  Télécharger
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </StyledDocumentItem>
  );
};

const StyledDocumentList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  max-width: 100%;
  overflow-y: scroll;
  padding: 10px 0px;
`;

const StyledDocumentItem = styled.div`
  width: 170px;
  height: 230px;
  padding: 10px;
  border-radius: 20px;
  background: white;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .doc__name {
    font-size: 13px;
    text-align: center;
    margin-top: -15px;
    font-weight: 400;
    letter-spacing: 1.1px;
  }

  & .doc__date {
    font-weight: 200;
    margin-top: 15px;
    text-align: center;
    font-size: 12px;
  }
`;

export default FileItem;
